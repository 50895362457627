import type { FoldingCardProp } from "@components/FoldingCardProp.js";
import type { Tenant } from "@core/schema/Tenant.js";
import { getCloudSolutionsPath } from "@core/schema/paths/getCloudSolutionsPath.js";

export function getReasonsForCoop(tenant: Tenant): FoldingCardProp[] {
	return [
		{
			title: "Neděláme krabicová řešení",
			desc: "Ke každému klientovi přistupujeme individuálně a připravujeme mu IT řešení na míru.",
			bg: "bg-fstgrey-400",
		},
		{
			title: "Razíme kvalitu, ne kvantitu",
			desc: "Nesekáme projekty jako Baťa cvičky. Raději děláme méně projektů a pořádně.",
			bg: "bg-fstgrey-500",
		},
		{
			title: "Úspora nákladů",
			desc: "Nafouknuté fakturace? To ve Fastest nevedeme. Odvedenou práci podrobně vykazujeme.",
			bg: "bg-fstgrey-600",
		},
		{
			title: "Vyvíjíme na cloudu",
			desc: `Specializujeme se na [${getCloudSolutionsPath(tenant)}][[cloudová řešení]], tím vám ušetříme peníze za servery.`,
			bg: "bg-fstgrey-700",
		},
		{
			title: "Nejsme jen webaři",
			desc: "Máme přesah do narovnávání firemních procesů, digitalizace nebo automatizace.",
			bg: "bg-fstgrey-800",
		},
		{
			title: "100% stabilita",
			desc: "Postaráme se, aby vaše systémy fungovaly 100% bez výpadků.",
			bg: "bg-fstgrey-900",
		},
	];
}
