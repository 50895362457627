<script lang="ts">
	import Formatted from "@components/common/Formatted.svelte";
	import { Background } from "./Background.js";
	import type { FoldingCardProp } from "./FoldingCardProp.js";

	const { cards, color }: { cards: FoldingCardProp[]; color?: Background } = $props();

	const cardElement = $state<HTMLElement[]>([]);
	const descriptionElement = $state<HTMLElement[]>([]);

	let activeIndex = $state<number | null>(null);

	function getHeight(
		_wrapperHeight: number,
		_wrapperWidth: number,
		card: HTMLElement | undefined,
		description: HTMLElement | undefined,
	): number {
		let height = 0;

		if (card) {
			height +=
				parseFloat(card.computedStyleMap().get("padding-bottom")?.toString() ?? "") +
				parseFloat(card.computedStyleMap().get("border-width")?.toString() ?? "");
		}

		if (description) {
			height += description.clientHeight;
		}

		return height;
	}

	function onEnter(index: number): void {
		activeIndex = index;
	}

	function onLeave(): void {
		activeIndex = null;
	}

	let wrapperHeight = $state(0);
	let wrapperWidth = $state(0);
</script>

<div
	class="grid grid-cols-[auto,1fr] gap-x-6 lg:gap-x-16"
	bind:clientHeight={wrapperHeight}
	bind:clientWidth={wrapperWidth}
>
	{#each cards as { title, desc, bg }, index (index)}
		{@const last = index === cards.length - 1}
		{@const card = cardElement[index]}
		{@const cardSpacings = card
			? parseFloat(card.computedStyleMap().get("padding-bottom")?.toString() ?? "") +
				parseFloat(card.computedStyleMap().get("border-width")?.toString() ?? "")
			: 0}
		{@const bottomMargin = last ? 0 : getHeight(wrapperHeight, wrapperWidth, card, descriptionElement[index])}

		<div
			role="tooltip"
			bind:this={cardElement[index]}
			style:z-index={index + 1}
			class={[
				"col-span-full relative grid grid-cols-subgrid transition-all top-0 duration-300 gap-x-6 lg:gap-x-14 rounded-[1.25rem] border-4 py-6 md:py-7 px-4 md:pl-10 md:pr-20",
				color === Background.Onyx && bg ? `${bg} border-fstonyx` : `bg-white border-fstlight-100`,
				last ? "" : "rounded-b-none",
			]}
			style:top={activeIndex === index ? `-${bottomMargin - cardSpacings}px` : "0"}
			style:margin-bottom="-{bottomMargin}px"
			onmouseenter={(): void => {
				if (!last) {
					onEnter(index);
				}
			}}
			onmouseleave={(): void => {
				if (!last) {
					onLeave();
				}
			}}
		>
			<div class="text-fstgreen font-loos-wide">{index + 1}</div>
			<div class="flex flex-col gap-6 relative">
				{#if title}
					<h3 class={["text-lg", color === Background.Onyx && bg ? `text-white` : `text-fstonyx`]}>
						<Formatted text={title} />
					</h3>
				{/if}
			</div>

			{#if desc}
				<p
					bind:this={descriptionElement[index]}
					class={[
						"col-start-2 mt-6 mb-0 transition-all duration-300",
						last ? "" : "pb-6",
						color === Background.Onyx && bg ? `text-white` : `text-fstgrey-200`,
					]}
				>
					<Formatted text={desc} />
				</p>
			{/if}
		</div>
	{/each}
</div>
